import Splide from '@splidejs/splide';

export default class Slider {
  constructor() {
    document.addEventListener( 'DOMContentLoaded', function() {
      if (document.querySelector('.splide-teams')) {
        const splideTeams = new Splide('.splide-teams', {
          type     : 'loop',
          speed    : 500,
          autoplay : true,
          interval : 3000,
          gap : '2em'
        });

        splideTeams.mount();
      }

      if (document.querySelector('.hero-slider')) {
        const heroSlider = new Splide('.hero-slider', {
          type     : 'loop',
          speed    : 500,
          autoplay : true,
          interval : 4000,
          gap : '2em',
          pauseOnHover: false,
        });

        heroSlider.mount();
      }
    });
  }
}
